import CIcon from '@coreui/icons-react';
import { CButton, CForm, CLabel } from '@coreui/react';
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { REPORT_NAMES, REPORT_TAB_TYPES, VARIABLE_OPERATORS_REPORT } from '../../../../constants/analytics';
import { EventExplorerContext } from './EventExplorer';

import {
     SortableContainer,
     SortableElement,
     SortableHandle,
} from 'react-sortable-hoc';

function arrayMove(array, from, to) {
     const slicedArray = array.slice();

     slicedArray.splice(
          to < 0 ? array.length + to : to,
          0,
          slicedArray.splice(from, 1)[0]
     );

     return slicedArray;
}

const SortableMultiValue = SortableElement(
     (props) => {
     const onMouseDown = (e) => {
          e.preventDefault();
          e.stopPropagation();
     };

     const innerProps = { ...props.innerProps, onMouseDown };
     return <components.MultiValue {...props} className="zIndex-sortable" innerProps={innerProps} />;
});
   
const SortableMultiValueLabel = SortableHandle(
     (props) => <components.MultiValueLabel {...props} />
);

const SortableSelect = SortableContainer(Select) 

const ChildMenu = ({ defaultValue, MenuList, handleChangeField, type, options, allOption = [], isMulti }) => {
     const {
          reportName,
          filterMetric,
          setFilterMetric,
          setFilterEvent,
          filterProperties,
          setFilterProperties,
          filterEvent,
          setIsFilterConversion,
          showData,
          isDirect,
          handleFindView,
          setIsDirect,
          setWeight,
          weight,
          setIsMapView,
          setIsApplyMetric,
          setIsHandleParams,
     } = useContext(EventExplorerContext);

     const [selected, setSelected] = useState(defaultValue);

     useEffect(() => setSelected(defaultValue), [defaultValue])

     const disableChange = reportName === REPORT_NAMES.ECOMMERCE_PURCHASE_FLOW;
     const handleSubmit = (values) => {
          if (!showData) {
               return;
          }
          if (
               (reportName === REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT || reportName === REPORT_NAMES.ECOMMERCE_ITEMS) &&
               [REPORT_TAB_TYPES.MODEL, REPORT_TAB_TYPES.DIMENSIONS].includes(type)
          ) {
               if (type === REPORT_TAB_TYPES.DIMENSIONS) {
                    setFilterEvent([{ type: '', value: '', id: '', operator: 'ct', optionsOperator: VARIABLE_OPERATORS_REPORT }]);
                    handleChangeField([values]);
               } else {
                    handleChangeField(values);
               }
          } else {
               switch (type) {
                    case REPORT_TAB_TYPES.DIMENSIONS:
                         break;
                    case REPORT_TAB_TYPES.PROPERTIES:
                         break;
                    case REPORT_TAB_TYPES.METRICS:
                         setIsFilterConversion(true);
                         break;

                    default:
                         break;
               }
               handleChangeField(values);
          }
          setIsHandleParams(false);
          switch (type) {
               case REPORT_TAB_TYPES.DIMENSIONS:
                    handleFindView(REPORT_TAB_TYPES.DIMENSIONS, values);
                    break;
               case REPORT_TAB_TYPES.PROPERTIES:
                    handleFindView(REPORT_TAB_TYPES.PROPERTIES, values);
                    break;
               case REPORT_TAB_TYPES.METRICS:
                    setIsApplyMetric(false);
                    handleFindView(REPORT_TAB_TYPES.METRICS, values);
                    break;
               case REPORT_TAB_TYPES.MODEL:
                    handleFindView(REPORT_TAB_TYPES.MODEL,  { ...values, noneDirect: isDirect });
                    break;

               default:
                    break;
          }
     };

     const removeSelectMulti = disableChange
          ? {
                 MultiValueRemove: () => null,
            }
          : {};
     const checkClearAll = (type) => {
          const propertyFilterValues = filterProperties.map((item) => item.value);
          const metricFilterValues = filterMetric.map((item) => item.value);

          if (
               (type === REPORT_TAB_TYPES.PROPERTIES && filterEvent.some((item) => propertyFilterValues.includes(item.type))) ||
               (type === REPORT_TAB_TYPES.METRICS && filterEvent.some((item) => metricFilterValues.includes(item.type)))
          )
               return true;

          return false;
     };
     const checkRemoveItem = (e, removedValue) => {
          if (
               reportName === REPORT_NAMES.FORM_CATEGORY_REPORT &&
               type === REPORT_TAB_TYPES.DIMENSIONS &&
               removedValue &&
               removedValue.value === 'formId'
          ) {
               return true;
          }

          if (e.length === 0 && type === REPORT_TAB_TYPES.DIMENSIONS) {
               return true;
          } else if (e.length > 0 && type === REPORT_TAB_TYPES.DIMENSIONS) {
               return false;
          }

          if (filterEvent.find((el) => el.type === removedValue.value)) {
               return true;
          }

          return false;
     };
     const handleClearAll = (setValues) => {
          if (!showData) return;

          if (checkClearAll(type)) {
               toast.error(`Can't remove ${type} because it exists in a filter.`);
               return;
          }

          if (type === REPORT_TAB_TYPES.PROPERTIES) {
               setValues([]);
               setFilterProperties([]);
          }
          if (type === REPORT_TAB_TYPES.METRICS) {
               setValues([]);
               setFilterMetric([]);
          }

          switch (type) {
               case REPORT_TAB_TYPES.PROPERTIES:
                    handleFindView(REPORT_TAB_TYPES.PROPERTIES, []);
                    break;
               case REPORT_TAB_TYPES.METRICS:
                    handleFindView(REPORT_TAB_TYPES.METRICS, []);
                    break;
               default:
                    break;
          }

     };
     const handleChangeDirect = (e) => {
          e.preventDefault();
          const noneDirect = !isDirect;
          setIsMapView(false);
          setIsDirect(noneDirect);
          setWeight({ ...weight, noneDirect: noneDirect });
          handleFindView(REPORT_TAB_TYPES.MODEL, { ...weight, noneDirect: noneDirect });
     };

     const onSortEnd = ({ oldIndex, newIndex }) => {
          const newValue = arrayMove(selected, oldIndex, newIndex);

          setSelected(newValue);
     };

     return (
          <>
               <Formik
                    initialValues={selected}
                    // validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize
               >
                    {({ values, handleSubmit, setValues }) => {
                         const handleChangeValue = (e, removedValue) => {
                              if (
                                   (reportName === REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT ||
                                        reportName === REPORT_NAMES.ECOMMERCE_ITEMS) &&
                                   [REPORT_TAB_TYPES.MODEL, REPORT_TAB_TYPES.DIMENSIONS].includes(type)
                              ) {
                                   setValues(e);
                                   setSelected(e);
                              } else {
                                   const selectItem = e || [];
                                   const findSelectAll = selectItem.find((item) => item.label === 'Select All');
                                   if (removedValue) {
                                        if (checkRemoveItem(selectItem, removedValue)) {
                                             if (type === REPORT_TAB_TYPES.DIMENSIONS) {
                                                  if (
                                                       reportName === REPORT_NAMES.FORM_CATEGORY_REPORT &&
                                                       type === REPORT_TAB_TYPES.DIMENSIONS &&
                                                       removedValue &&
                                                       removedValue.value === 'formId'
                                                  ) {
                                                       toast.error(`Can't remove FormID dimensions`);
                                                  } else {
                                                       toast.error(`Can't remove all dimensions`);
                                                  }
                                             } else {
                                                  toast.error(`Can't remove ${type} because it exists in a filter.`);
                                             }
                                             return;
                                        }
                                   }
                                   if (findSelectAll) {
                                        setValues(allOption);
                                        setSelected(allOption);
                                   } else {
                                        setValues(selectItem);
                                        setSelected(selectItem);
                                   }
                              }
                         };

                         return (
                              <CForm onSubmit={handleSubmit} noValidate>
                                   <div className="report-select">
                                        <div className="report-select-header">
                                             {[REPORT_TAB_TYPES.MODEL].includes(type) ? (
                                                  <p>Select the model</p>
                                             ) : (
                                                  <p>Select the columns to display in the table</p>
                                             )}
                                             <div className="d-flex align-items-center justify-content-end filter-report">
                                                  {!disableChange && ![REPORT_TAB_TYPES.DIMENSIONS, REPORT_TAB_TYPES.MODEL].includes(type) ? (
                                                       <CButton onClick={() => handleClearAll(setValues)}>
                                                            <CLabel>clear all</CLabel>
                                                            <CIcon name="iconDeleteCircle" />
                                                       </CButton>
                                                  ) : (
                                                       ``
                                                  )}
                                                  {[REPORT_TAB_TYPES.MODEL].includes(type) ? (
                                                       <div className="filter-report">
                                                            <CButton
                                                                 className={` filter-button ${isDirect ? 'is-direct' : ''}`}
                                                                 onClick={(e) => handleChangeDirect(e)}
                                                            >
                                                                 <CLabel>Exclude Direct</CLabel>
                                                            </CButton>
                                                       </div>
                                                  ) : (
                                                       ``
                                                  )}
                                                  {![].includes(reportName) &&
                                                  (type === REPORT_TAB_TYPES.DIMENSIONS && options.length <= 1 && allOption.length <= 1
                                                       ? false
                                                       : true) ? (
                                                       <CButton
                                                            type="submit"
                                                            className={` filter-button ${
                                                                 JSON.stringify(values) === JSON.stringify(defaultValue) ? 'disabled' : ''
                                                            }`}
                                                       >
                                                            <CLabel>Apply</CLabel>
                                                       </CButton>
                                                  ) : (
                                                       ``
                                                  )}
                                             </div>
                                        </div>

                                        <div className="events-multiselect">
                                             {[REPORT_TAB_TYPES.METRICS, REPORT_TAB_TYPES.PROPERTIES, REPORT_TAB_TYPES.DIMENSIONS].includes(type) ? (
                                                  <SortableSelect
                                                       className="text-dark"
                                                       useDragHandle
                                                       // react-sortable-hoc props:
                                                       axis="xy"
                                                       onSortEnd={onSortEnd}
                                                       distance={4}
                                                       // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                                                       getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                                                       // react-select props:
                                                       isMulti={isMulti}
                                                       options={values.length === allOption.length ? [] : options}
                                                       value={values}
                                                       onChange={(e, item) => handleChangeValue(e, item.removedValue)}
                                                       components={{
                                                            MenuList,
                                                            MultiValue: SortableMultiValue,
                                                            MultiValueLabel: SortableMultiValueLabel,
                                                            ClearIndicator: null,
                                                            ...removeSelectMulti
                                                       }}
                                                       isClearable={true}
                                                       backspaceRemovesValue={true}
                                                       noOptionsMessage={() => <span>No options available.</span>}
                                                  />
                                             ) : (
                                                  <Select
                                                       className="text-dark"
                                                       options={values.length === allOption.length ? [] : options}
                                                       value={values}
                                                       onChange={(e, item) => handleChangeValue(e, item.removedValue)}
                                                       isMulti={isMulti}
                                                       isClearable={true}
                                                       backspaceRemovesValue={true}
                                                       components={{ ClearIndicator: null, ...removeSelectMulti, MenuList }}
                                                       noOptionsMessage={() => <span>No options available.</span>}
                                                  />
                                             )}
                                        </div>
                                   </div>
                              </CForm>
                         );
                    }}
               </Formik>
          </>
     );
};

export default ChildMenu;
