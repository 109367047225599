import CIcon from '@coreui/icons-react';
import { CButton, CCard, CSwitch } from '@coreui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
     handleCompleteOwnershipTransfer,
     handleCrawlDomain,
     handleFetchCrawlerStatus,
     resetAllPreview,
     setDomains,
} from '../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../apiCaller';
import {
     ADMIN_ACCOUNT_UPDATE,
     API_CLIENT_ACCOUNT_DOWNGRADE,
     API_CLIENT_ACCOUNT_UPGRADE,
     API_CLIENT_NUMBER_PAGES_CRAWL,
     API_CLIENT_SAVE_CARD_TO_DOWNGRADE,
     API_CLIENT_SAVE_CARD_TO_UPGRADE,
     CLIENT_ALL_DOMAIN,
     JOB_STATUS,
} from '../../../../../constants';
import { getStripeCardImage } from '../../../../../helpers/cms/subscriber';
import { dateDiffIndays, roundTo2DecimalPlaces, toastError } from '../../../../../utils';
import { PaymentInfo } from '../../../../general/payment';
import { blackListCrawl } from '../../website/blackListDomain';

const PaymentMethod = ({ selectedPackage, toggleShowPayment, url }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const packages = useSelector((state) => state.subscriber.packages);
     const sandboxPackage = packages.find((item) => item.friendlyName === 'Sandbox');
     const domains = useSelector((state) => state.subscriber.domains);
     const { id: packageId, pricePerMonth, pricePerYear, amountSavedOnAnnual, percentSavedOnAnnual } = selectedPackage || sandboxPackage;
     const {
          id: accountId,
          stripeCardType,
          stripeCardLast4,
          packagePricePerMonth: currentPackagePricePerMonth,
          created_at: dateCreated,
          trialEndTime,
          balance,
          confirmPayment,
     } = activeAccount;
     const firstUpgrade = dateDiffIndays(dateCreated, trialEndTime) === 0 && currentPackagePricePerMonth === 0 ? true : false;
     const isSwitchPackage = currentPackagePricePerMonth === pricePerMonth;
     const isSwitchFreePackage = isSwitchPackage && pricePerMonth === 0;

     // Did this account already add a payment method
     const accountHasPayment = stripeCardType && stripeCardLast4;
     const isUpgrade = currentPackagePricePerMonth <= pricePerMonth;
     const doNeedPayment = !accountHasPayment && !isSwitchFreePackage;

     const [jobs, setJobs] = useState(null);
     const [isLoading, setIsLoading] = useState(false);
     const [annualBilling, setAnnualBilling] = useState(false);
     const [showPaymentForm, setShowPaymentForm] = useState(doNeedPayment);
     const [accountDomains, setAccountDomains] = useState(() => (domains ? domains.initialDomains : []));

     const toggleShowPaymentForm = useCallback(() => {
          setShowPaymentForm(!showPaymentForm);
     }, [showPaymentForm]);

     const toggleAnnualBilling = useCallback(() => {
          setAnnualBilling(!annualBilling);
     }, [annualBilling]);

     // const onSuccess = (response) => {
     //      const { account } = response.data;
     //      const newAccount = setPackageToAccount(account, selectedPackage, isOwner);
     //      const activeAccountIndex = findAccountIndex(accounts, activeAccountId);
     //      let newAccounts = [...accounts];
     //      newAccounts[activeAccountIndex] = { ...newAccount };

     //      toggleShowPayment();
     //      dispatch(setSubscriberState({
     //           accounts: newAccounts,
     //           activeAccount: newAccount
     //      }));
     //      dispatch(setShowUpgradeAccountPopup(false));
     // }

     const handleCrawl = () => {
          if (activeAccount.isRunningV2) {
               const accountDomains = activeAccount.domains ? JSON.parse(activeAccount.domains) : [];
               const toDay = new Date();
               const updateDomains = [];
               const crawlerDomains = [];

               for (const accountDomain of accountDomains) {
                    const job =
                         jobs && jobs.find((j) => j.domain.replace('https://', '').replace('http://', '').replace(/\/$/, '') === accountDomain.name);

                    if (job && job.status === JOB_STATUS.PAUSED) {
                         delete accountDomain['stopCrawl'];

                         accountDomain.isCrawling = true;
                         accountDomain.lastDateCrawl = toDay;
                         crawlerDomains.push(accountDomain.name);
                    }

                    updateDomains.push(accountDomain);
               }

               // Update domains
               const updateAccount = {
                    accountId,
                    domains: JSON.stringify(updateDomains),
                    stopCrawl: null,
               };
               callTokenApi(ADMIN_ACCOUNT_UPDATE, 'PUT', updateAccount).then(() => {
                    for (const domain of crawlerDomains) {
                         dispatch(handleCrawlDomain({ domain, accountId }));
                    }
               });
          } else {
               for (const accountDomain of accountDomains) {
                    if (
                         !blackListCrawl.includes(accountDomain.name) &&
                         accountDomain.urlsCrawledLength >= (process.env.REACT_APP_ENV === 'production' ? 500 : 100)
                    ) {
                         dispatch(handleCrawlDomain({ domain: accountDomain.name, accountId }));
                    }
               }
          }
     };

     // Get all Domains
     const getAllDomains = () => {
          callTokenApi(`${CLIENT_ALL_DOMAIN}${activeAccount.id}`, 'GET', null)
               .then((response) => {
                    let initialDomains = [],
                         listDisabled = [];
                    if (response.status === 200) {
                         const { domains: resDomains } = response.data;

                         if (resDomains.length > 0) {
                              // Initialize to show, update or remove domains
                              resDomains.forEach((item, index) => {
                                   initialDomains.push({
                                        ...item,
                                        id: `savedDomain${index + 1}`,
                                        name: item.name,
                                        scriptInstalled: item.scriptInstalled,
                                        tempScriptInstalled: item.scriptInstalled,
                                        verificationDisabled: item.verificationDisabled,
                                        isIframeBlocked: item.isIframeBlocked,
                                        isCSPBlocked: item.isCSPBlocked,
                                   });
                                   listDisabled.push(true);
                              });

                              dispatch(
                                   setDomains({
                                        ...resDomains,
                                        initialDomains,
                                        addedDomains: [],
                                        editedDomains: [],
                                        listDisabled,
                                        numOfDomains: resDomains.length,
                                   })
                              );
                         } else {
                              dispatch(
                                   setDomains({
                                        ...resDomains,
                                        initialDomains: [{ id: 'newDomain1', name: '', scriptInstalled: false }],
                                        addedDomains: [{ id: 'newDomain1', name: '', scriptInstalled: false }],
                                        editedDomains: [],
                                        listDisabled: [false],
                                        numOfDomains: 1,
                                   })
                              );
                         }
                    }
               })
               .finally(() => {});
     };

     const countUrlCrawled = async (domainFull = '') => {
          const domain = domainFull.replace('https://', '').replace('http://', '').replace(/\/$/, '');
          const response = await callTokenApi(API_CLIENT_NUMBER_PAGES_CRAWL, 'POST', { accountId, domain });

          let lengthUrlCrawled = 0;

          try {
               lengthUrlCrawled = response.data.lengthUrlCrawled;
          } catch (error) {}
          return lengthUrlCrawled;
     };

     useEffect(() => {
          if (!jobs && activeAccount.isRunningV2) {
               dispatch(handleFetchCrawlerStatus({ accountId, setJobs }));
          }
     }, [accountId]); // eslint-disable-line

     useEffect(() => {
          if (!domains || domains.numOfDomains === 0) {
               getAllDomains();
          } else {
               if (!activeAccount.isRunningV2) {
                    const fetchS3 = async () => {
                         const newDomains = [...domains.initialDomains];
                         for (let index = 0; index < newDomains.length; index++) {
                              const domain = newDomains[index];
                              const lengthCrawled = await countUrlCrawled(domain.name);
                              newDomains[index].urlsCrawledLength = lengthCrawled;
                         }
                         setAccountDomains(newDomains);
                    };
                    fetchS3();
               }
          }
     }, [domains]); //eslint-disable-line

     const onSubmit = (values, { setSubmitting }) => {
          const cardNumber = values.stripeCardNumber.replace(/_/gi, '').replace(/ /gi, '');
          const stripeCardExpiration = values.stripeCardExpiration;
          const expiration = stripeCardExpiration.split('/');
          const expMonth = expiration[0];
          const expYear = expiration[1];

          const data = {
               accountId,
               packageId,
               name: values.stripeCardName,
               cardNumber,
               expMonth,
               expYear,
               cvc: values.stripeCardCVC,
               annualBilling,
          };
          callTokenApi(`${isUpgrade ? API_CLIENT_SAVE_CARD_TO_UPGRADE : API_CLIENT_SAVE_CARD_TO_DOWNGRADE}`, 'POST', data)
               .then(async (response) => {
                    if (response.status === 200) {
                         // onSuccess(response);
                         // toast.success(isUpgrade ? 'Your account has been successfully upgraded!' : 'Your account has been downgraded!');
                         if (isUpgrade) {
                              handleCrawl();
                         }
                         await new Promise((resolve) => {
                              setTimeout(() => {
                                   resolve();
                              }, 2000);
                         });
                         if (confirmPayment) {
                              dispatch(
                                   handleCompleteOwnershipTransfer({ accountId, userId: activeAccount.userId, fetchUserAccount: false }, () =>
                                        window.location.reload()
                                   )
                              );
                         } else {
                              window.location.reload();
                         }
                    } else {
                         setSubmitting(false);
                         toastError(response);
                    }
               })
               .finally(() => {
                    dispatch(resetAllPreview(accountId));
               });
     };

     const handleUpgrade = () => {
          setIsLoading(true);

          const data = {
               accountId,
               packageId,
               annualBilling,
          };

          dispatch((dispatch) => {
               callTokenApi(`${API_CLIENT_ACCOUNT_UPGRADE}`, 'POST', data)
                    .then(async (response) => {
                         if (response.status === 200) {
                              // onSuccess(response);
                              // toast.success('Your account has been successfully upgraded!');
                              handleCrawl();
                              await new Promise((resolve) => {
                                   setTimeout(() => {
                                        resolve();
                                   }, 2000);
                              });
                              window.location.reload();
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => {
                         setIsLoading(false);
                         dispatch(resetAllPreview(accountId));
                    });
          });
     };

     const handleDowngrade = () => {
          setIsLoading(true);

          const data = {
               accountId,
               packageId,
               annualBilling,
          };

          callTokenApi(API_CLIENT_ACCOUNT_DOWNGRADE, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         // onSuccess(response);
                         // toast.success('Your account has been downgraded!');
                         window.location.reload();
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setIsLoading(false);
                    dispatch(resetAllPreview(accountId));
               });
     };

     return (
          <>
               <div className="account-header">
                    <CButton className="btn-back btn-back-choose" onClick={toggleShowPayment}>
                         <i className="fal fa-angle-left"></i>
                         Back
                    </CButton>
                    <CIcon name="logo-dark" height={34} />
                    <CCard className="choose-how-to-pay choose-pay-header">
                         <h3 className="text-choose">Choose how to pay</h3>
                         <h6 className="text-des">Something about this step.</h6>
                         <div className="sidebar-popup billing-infomation choose-pay-body">
                              <div className="sidebar-header">
                                   <div className="annual-billing">
                                        <CSwitch
                                             className="mb-0"
                                             color={annualBilling ? 'success' : 'light'}
                                             checked={annualBilling}
                                             size="lg"
                                             tabIndex="0"
                                             shape="pill"
                                             onChange={toggleAnnualBilling}
                                             disabled={isSwitchFreePackage}
                                        />
                                        <span className="annual-billing-text">Annual Billing</span>
                                        <span className={classNames('cost-per-year', { annually: annualBilling })}>
                                             $
                                             {annualBilling
                                                  ? `${roundTo2DecimalPlaces(pricePerYear / 12).toLocaleString('en-US')}`
                                                  : `${pricePerMonth.toLocaleString('en-US')}`}
                                        </span>
                                   </div>
                                   {annualBilling ? (
                                        <div className="description-price">
                                             <span className="annual-description">
                                                  Save {percentSavedOnAnnual}% (${amountSavedOnAnnual.toLocaleString('en-US')}) with annual billing.
                                             </span>
                                             <span className="float-right">${pricePerYear.toLocaleString('en-US')}/yr</span>
                                        </div>
                                   ) : (
                                        <span className="annual-description">
                                             Save {percentSavedOnAnnual}% (${amountSavedOnAnnual.toLocaleString('en-US')}) with annual billing.
                                        </span>
                                   )}
                                   {showPaymentForm ? (
                                        <>
                                             {accountHasPayment && (
                                                  <CButton className="use-current-card" onClick={toggleShowPaymentForm}>
                                                       <i className="fal fa-angle-left"></i>
                                                       Use card on file
                                                  </CButton>
                                             )}
                                             <PaymentInfo
                                                  onSubmit={onSubmit}
                                                  submitBtnText={isUpgrade ? 'Upgrade Now' : 'Downgrade Now'}
                                                  isUpgrade={isUpgrade}
                                                  annualBilling={annualBilling}
                                                  pricePerYear={pricePerYear}
                                                  pricePerMonth={pricePerMonth}
                                                  firstUpgrade={firstUpgrade}
                                                  balance={balance}
                                             />
                                        </>
                                   ) : (
                                        <>
                                             {!isSwitchFreePackage && (
                                                  <CCard className="current-payment-method-wrapper">
                                                       <p className="payment-method-title">Payment Method</p>
                                                       <div className="justify-content-between current-payment-card">
                                                            <div className="payment-hidden">
                                                                 {getStripeCardImage(stripeCardType)}
                                                                 <div className="hidden-payment-number">
                                                                      <div className="hidden-circle"></div>
                                                                      <div className="hidden-circle"></div>
                                                                      <div className="hidden-circle"></div>
                                                                      <div className="hidden-circle"></div>
                                                                 </div>
                                                                 <div className="hidden-payment-number">
                                                                      <div className="hidden-circle"></div>
                                                                      <div className="hidden-circle"></div>
                                                                      <div className="hidden-circle"></div>
                                                                      <div className="hidden-circle"></div>
                                                                 </div>
                                                                 <div className="hidden-payment-number">
                                                                      <div className="hidden-circle"></div>
                                                                      <div className="hidden-circle"></div>
                                                                      <div className="hidden-circle"></div>
                                                                      <div className="hidden-circle"></div>
                                                                 </div>
                                                                 <div className="payment-number">{stripeCardLast4}</div>
                                                            </div>
                                                            <CButton className="btn-change" onClick={toggleShowPaymentForm}>
                                                                 Change
                                                            </CButton>
                                                       </div>
                                                  </CCard>
                                             )}
                                             <CButton
                                                  color="success"
                                                  className="btn-save"
                                                  disabled={doNeedPayment || isLoading}
                                                  onClick={isUpgrade ? handleUpgrade : handleDowngrade}
                                             >
                                                  {isLoading ? (
                                                       <span className="dots-waiting">Waiting</span>
                                                  ) : isUpgrade ? (
                                                       isSwitchPackage ? (
                                                            'Switch Now'
                                                       ) : (
                                                            'Upgrade Now'
                                                       )
                                                  ) : (
                                                       'Downgrade Now'
                                                  )}
                                             </CButton>
                                        </>
                                   )}
                                   {/* <div className="text-center">
                                   {
                                        isUpgrade ? (
                                             <p className="payment-description">
                                                  Your subscription is paid {annualBilling ? 'annually' : 'monthly'}. We'll validate your credit card now and{" "}
                                                  will charge your card ${annualBilling ? pricePerYear.toLocaleString('en-US') : pricePerMonth.toLocaleString('en-US')} {firstUpgrade ? `after your ${TRIAL_DAYS} free days` : `today`}. If you are in a current billing cycle, we will reduce the amount charged today by the pro-rated, unused time on your current package.
                                             </p>
                                        ) : (
                                             <p className="payment-description">
                                                  We will refund you credit card for any prepaid portion of your current plan that has not been used.
                                             </p>
                                        )
                                   }
                              </div> */}
                              </div>
                         </div>
                    </CCard>
                    <div className="footer text-center">
                         <CIcon name="logo-dark" height={34} />
                         <div className="d-flex justify-content-center">
                              <div>
                                   <a className="text-footer" href={`${url}support/`}>
                                        Support
                                   </a>
                              </div>
                              <div>
                                   <a className="text-footer" href={`${url}terms-of-use/`}>
                                        Terms of Use
                                   </a>
                              </div>
                              <div>
                                   <a className="text-footer" href={`${url}privacy-policy/`}>
                                        Privacy & Data Policy
                                   </a>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
};

export default PaymentMethod;
