import React, { useContext, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CButton, CFormGroup, CInputCheckbox, CLabel, CSwitch } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import cloneDeep from 'lodash/cloneDeep';

import SegmentTooltip from './SegmentTooltip';
import { API_CLIENT_DEFAULT_REPORT_SEGMENT_STICKY_CONFIGS, API_CLIENT_SEGMENT_REPORT, BQ_JOB_STATUS, COMPONENT_NAME } from '../../../../../constants';
import { setListSegmentReport, setListSegmentReload } from '../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../apiCaller';
import { camelCase, toastError } from '../../../../../utils';
import { setFlexibleModal } from '../../../../../actions/common';
import { ConfirmRemovePopup } from '../../../../general/popup';
import { EventExplorerContext, handleGetSortSegment } from '../EventExplorer';
import { SEGMENT_ALL_DATA_DEFAULT_NAME } from '../../../../../constants/segment';

const SegmentDropdown = (props) => {
     const dispatch = useDispatch();
     const { reportName, segmentSticky, setSegmentSticky, setSort, sort, filterMetric, disableSort, listSegmentReportApply, setListSegmentReportApply } = useContext(EventExplorerContext);
     const {segmentsEdit, setSegmentsEdit, setSegmentsSelected, accountId, setShowPopup} = props
     const listSegmentReport = useSelector((state) => state.subscriber.listSegmentReport);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const enableFullAnalytics = useSelector((state) => state.subscriber.activeAccount.enableFullAnalytics);
     const showData = localStorage.getItem('llShowData') || window.location.search.includes('show-data=1') || activeAccount.behavioralAnalytics || enableFullAnalytics;

     const [showDeleteModal, setShowDeleteModal] = useState(false);
     const [saveLoading, setSaveLoading] = useState(false);

     const [selectedSidebar, setSelectedSidebar] = useState('All');
     const [clearAllSegment, setClearAllSegment] = useState([]);
     const [toggleSticky, setToggleSticky] = useState(segmentSticky);
     const [isLoading, setIsLoading] = useState(false);

     const sidebarItems = ['All', 'Default', 'Custom'];
     const [segments, setListSegments] = useState([...listSegmentReport]);
     const showSegments = cloneDeep(segments).map((seg) => {
          const existed = segmentsEdit.find((item) => item.id === seg.id);

          if (existed) {
               seg.apply = existed.apply;
          } 
          
          return seg;
         
     });

     const handleSelectSidebar = (item) => {
          setSelectedSidebar(item);
     };

     const getCurrentSegment = useCallback(() => {
          switch (selectedSidebar) {
               case 'Default':
                    return showSegments.filter((item) => !item.isCustom);
               case 'Custom':
                    return showSegments.filter((item) => item.isCustom);
               default:
                    return showSegments;
          }
     }, [selectedSidebar, showSegments]) // eslint-disable-line

     const handleChangeSelected = (id, status) => {
          const segmentEditing = listSegmentReport.find((seg) => seg.id === id);
          if (segmentEditing) {
               const existed = segmentsEdit.find((seg) => seg.id === id);
               if (segmentEditing.segmentReportId) {
                    if (existed) {
                         setSegmentsEdit((prev) => {
                              return prev.map((item) => {
                                   if (item.id === id) {
                                        item.apply = status;
                                        if(status === false) {
                                             item.applyChart = false
                                        }
                                   }
                                   return item;
                              });
                         });
                    } else {
                         setSegmentsEdit((prev) => {
                              return [
                                   ...prev,
                                   {
                                        id: segmentEditing.id,
                                        segmentReportId: segmentEditing.segmentReportId,
                                        apply: status,
                                        applyChart: false,
                                        type: 'update',
                                   },
                              ];
                         });
                    }
               } else {
                    if (existed) {
                         setSegmentsEdit((prev) => {
                              return prev.map((item) => {
                                   if (item.id === id) {
                                        item.apply = status;
                                        if(status === false) {
                                             item.applyChart = false
                                        }
                                   }
                                   return item;
                              });
                         });
                    } else {
                         setSegmentsEdit((prev) => {
                              return [
                                   ...prev,
                                   {
                                        id: segmentEditing.id,
                                        apply: status,
                                        type: 'add',
                                   },
                              ];
                         });
                    }
               }
          }
     };

     const handleApply = () => {
          const prevTemporarySelected = showSegments.filter((item) => item.apply);
          if (prevTemporarySelected.length > 4) {
               toast.error('You can only select up to 4 segments.');
               return;
          }
          const handleGetSegmentEdit = () =>{
               const newSegmentEdit = [...segmentsEdit];
               showSegments.forEach((item)=>{
                    const segment = listSegmentReportApply.find((el)=>el.id === item.id);
                    const segmentEdit = segmentsEdit.find((el)=>el.id === item.id);
                    if(segment && !segmentEdit){
                         newSegmentEdit.push({id: segment.id, segmentReportId: segment.segmentReportId, apply: segment.apply, type: 'update', isUpdateAt: true});
                    }
               })
               return newSegmentEdit;
          }
          const data = {
               reportName,
               segmentsEdit: handleGetSegmentEdit(),
               accountId,
               segmentSticky: toggleSticky,
          };
          const hasSort = filterMetric.length && segmentsEdit.some((seg)=> sort.fieldName && sort.fieldName.includes(camelCase(seg.id)));
          const changeSort = listSegmentReportApply.length === 0 ? true : hasSort;
          if (segmentsEdit.length) {
               setIsLoading(true);
               callTokenApi(`${API_CLIENT_SEGMENT_REPORT}`, 'PUT', data).then((response) => {
                    if (response.status === 200) {
                         const { segmentReport } = response.data;
                         const newUpdate = [...listSegmentReport].map((seg) => {
                              const existed = segmentReport.find((item) => item.segmentId === seg.id);
                              if (existed) {
                                   seg.apply = existed.apply;
                                   seg.segmentReportId = existed.id;
                                   seg.orderBy = existed.updated_at;
                              } else {
                                   seg.apply = false; 
                              }

                              return seg;
                         });
                         setSegmentsEdit([]);
                         const newListSegmentApply = newUpdate.filter((item) => item.apply);
                         if(newListSegmentApply.length > 0) {
                              if(changeSort) {
                                   setSort(
                                        handleGetSortSegment({
                                             listSegmentReportApply: newUpdate.filter(
                                                  (item) => item.apply && item.queryStatus === BQ_JOB_STATUS.DONE
                                             ),
                                             filterMetric,
                                        })
                                   );
                              }
                         } else if(filterMetric[0] && !disableSort){
                              setSort({
                                   fieldName: filterMetric[0].value,
                                   isDesc: true,
                              });
                         }
                         dispatch(setListSegmentReport(newUpdate));
                         dispatch(setListSegmentReload(newUpdate));
                         setListSegmentReportApply(newUpdate.filter((item) => item.apply && item.queryStatus === BQ_JOB_STATUS.DONE).sort((a, b) => new Date(a.orderBy) - new Date(b.orderBy)))
                         setIsLoading(false);
                         setShowPopup(false);
                         toast.success('Update successfully!');
                    } else {
                         toastError(response);
                    }
               });
          }
     };

     const onClickCreate = () => {
          dispatch(
               setFlexibleModal({
                    show: true,
                    reportId: null,
                    component: COMPONENT_NAME.SEGMENT_DETAIL,
                    componentLv2: null,
               })
          );
     };

     const onClickEdit = (id) => {
          if (!showData) {
               return;
          }
          dispatch(
               setFlexibleModal({
                    show: true,
                    reportId: id,
                    component: COMPONENT_NAME.SEGMENT_DETAIL,
               })
          );
     };

     const handleConfirmClearAllClick = () => {
          //fillter segment report db
          const data = { reportName, accountId, segmentsEdit: clearAllSegment, segmentSticky: toggleSticky }; // Adjust data structure as needed
          setSaveLoading(true);

          callTokenApi(`${API_CLIENT_SEGMENT_REPORT}`, 'PUT', data)
               .then((response) => {
                    if (response.status === 200) {
                         const newUpdate = [...listSegmentReport].map((seg) => ({ ...seg, apply: false }));
                         if(filterMetric.length) {
                              setSort({
                                   fieldName: filterMetric[0].value,
                                   isDesc: true,
                              });
                         }
                         dispatch(setListSegmentReport(newUpdate));
                         dispatch(setListSegmentReload(newUpdate));
                         setListSegments(newUpdate);
                         setShowDeleteModal(false);
                         setClearAllSegment([]);
                         setSegmentsEdit([]);
                         setSegmentsSelected();
                         setListSegmentReportApply([])
                         toast.success('Clear all success!');
                    }
               })
               .finally(() => setSaveLoading(false));
     };

     const handleClearAll = () => {
          const _segmentsEdit = listSegmentReport
               .filter((item) => item.apply && item.segmentReportId)
               .map((item) => {
                    return {
                         id: item.id,
                         segmentReportId: item.segmentReportId,
                         apply: false,
                         applyChart: false,
                         type: 'update',
                    };
               });

          if (_segmentsEdit.length) {
               setClearAllSegment(_segmentsEdit);
               setShowDeleteModal(true);
          } else {
               setSegmentsEdit([]);
               setListSegments(listSegmentReport);
          }
     };

     const handleOnToggleStatus = () => {
          const newToggleSticky = !toggleSticky; // Toggle the sticky status
          setToggleSticky(newToggleSticky);

          const data = {
               accountId,
               reportName,
               segmentSticky: newToggleSticky,
          };

          callTokenApi(`${API_CLIENT_DEFAULT_REPORT_SEGMENT_STICKY_CONFIGS}`, 'PUT', data).then((response) => {
               if (response.status === 200) {
                    setSegmentSticky(newToggleSticky);
                    if(newToggleSticky) {
                         toast.success('Turn on sticky !');
                    } else {
                         toast.success('Turn off sticky !');
                    }
               } else {
                    toastError(response);
               }
          });
     };

     const maximumSegmentSelect = showSegments.filter((item) => item.apply);

     return (
          <>
               <div className="segment-panel d-flex flex-column">
                    <div className="header d-flex align-items-center justify-content-between">
                         <p>Add Segments</p>
                         <div className="d-flex align-items-center justify-content-between">
                              {maximumSegmentSelect.length === 4 && <span className="text-maximum">Maximum of 4 segments only.</span>}
                              <CButton className="clear-all text-transform-none" onClick={() => handleClearAll()} disabled={isLoading || (maximumSegmentSelect.length === 0 && listSegmentReportApply.length === 0)}>
                                   <CLabel className="primary mb-0">Clear all</CLabel>
                              </CButton>
                         </div>
                    </div>
                    <div className="content d-flex">
                         <div className="sidebar-segment">
                              {sidebarItems.map((item) => (
                                   <div
                                        key={item}
                                        className={`sidebar-segment-item ${selectedSidebar === item ? 'selected' : ''}`}
                                        onClick={() => handleSelectSidebar(item)}
                                   >
                                        {item}
                                   </div>
                              ))}
                         </div>
                         <div className="segments">
                              <div className="segment-options-container">
                                   {getCurrentSegment().map((item, index) => (
                                        <div className="list-content d-flex align-items-center justify-content-between" key={index}>
                                             {(item.queryStatus === BQ_JOB_STATUS.DONE || item.queryStatus === BQ_JOB_STATUS.IN_PROGRESS) && (
                                                  <div className="segment-option d-flex align-items-center justify-content-between">
                                                       <div className="d-flex align-items-center justify-content-start">
                                                            <CFormGroup variant="custom-checkbox" inline>
                                                                 <CInputCheckbox
                                                                      custom
                                                                      id={`${index}-segment`}
                                                                      name={`${index}-segment`}
                                                                      checked={typeof item.apply !== 'undefined' ? item.apply : false}
                                                                      onChange={(e) => handleChangeSelected(item.id, e.target.checked)}
                                                                      disabled={
                                                                           (maximumSegmentSelect.length === 4 && !item.apply) ||
                                                                           isLoading ||
                                                                           item.queryStatus !== BQ_JOB_STATUS.DONE
                                                                      }
                                                                 />
                                                                 <CLabel variant="custom-checkbox" htmlFor={`${index}-segment`}>
                                                                      {item.name}
                                                                 </CLabel>
                                                            </CFormGroup>
                                                            {item.description && (
                                                                 <Tippy
                                                                      className="info-icon ml-1 mt-1"
                                                                      content={
                                                                           <div className="tooltip-content">
                                                                                <div>{item.description}</div>
                                                                           </div>
                                                                      }
                                                                      placement="right-start"
                                                                      maxWidth="640px"
                                                                      offset={[1, 4]}
                                                                      theme="left-back"
                                                                      arrow={
                                                                           '<svg viewBox="0 0 6 13"><g id="b671136e-83cf-4fb9-b98e-be51e54c953e" data-name="Layer 2"><g id="b5fae9b2-5e0b-421a-8a35-db7330bc1b57" data-name="Layer 1"><g id="b24f8614-20ef-4cec-a50a-7ced35d16814" data-name="Layer 2"><g id="fa01feaf-579e-4c20-ba60-43d232f559d5" data-name="Layer 1-2"><path d="M6,0H4.14c-7,0-3,6.06-3,6.06L6,13V0Z" style="fill:#19222b"></path></g></g></g></g></svg>'
                                                                      }
                                                                 >
                                                                      <span style={{ paddingBottom: '6px', marginRight: '10px' } }>
                                                                           <CIcon
                                                                                name="iconAlertCircle"
                                                                                style={{ cursor: 'pointer', width: '16px', height: '20px' }}
                                                                           />
                                                                      </span>
                                                                 </Tippy>
                                                            )}
                                                            {item.queryStatus === BQ_JOB_STATUS.IN_PROGRESS && (
                                                                 <Tippy
                                                                      className="ml-1 mt-1"
                                                                      content={
                                                                           <div className="tooltip-content">
                                                                                <div className='title'>You cannot select this segment until its process is finished!</div>
                                                                           </div>
                                                                      }
                                                                      placement="top"
                                                                      appendTo={() => document.body}
                                                                      maxWidth="500px"
                                                                      theme="segment"
                                                                 >
                                                                      <div className='tooltip-tag'>
                                                                          <span className='bg-secondary badge segment' style={{cursor: 'pointer'}}>In Progress</span> 
                                                                      </div>
                                                                 </Tippy>
                                                            )}
                                                       </div>
                                                       {item.name !== SEGMENT_ALL_DATA_DEFAULT_NAME && (
                                                            <SegmentTooltip
                                                                 icon={'iconEllipsisVertical'}
                                                                 buttonLink={selectedSidebar === 'Default' ? 'View rule' : 'Edit rule'}
                                                                 iconView={selectedSidebar === 'Default' ? 'iconEyeBlue' : 'iconPencilBlue'}
                                                                 onClickEdit={() => onClickEdit(item.id)}
                                                            />
                                                       )} 
                                                       
                                                  </div>
                                             )}
                                        </div>
                                   ))}
                              </div>
                         </div>
                    </div>
                    <div className="footer-segment d-flex align-items-center justify-content-between">
                         <div className="wrapper-sticky">
                              <div className="d-flex align-items-center toggle-sticky">
                                   <CSwitch
                                        color={'success'}
                                        checked={toggleSticky}
                                        value={toggleSticky ? 'on' : 'off'}
                                        shape="pill"
                                        tabIndex="0"
                                        size="sm"
                                        onChange={handleOnToggleStatus}
                                        disabled={isLoading}
                                   />
                                   <span className="ml-2">Sticky</span>
                                   <Tippy
                                        className="info-icon ml-1 mt-3"
                                        content={
                                             <div className="tooltip-content">
                                                  <div>{'Applies these settings as you navigate across reports during this reporting session.'}</div>
                                             </div>
                                        }
                                        placement="bottom-start"
                                        maxWidth="640px"
                                        offset={[1, 2]}
                                        theme="left-top-back"
                                        arrow={
                                             '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 9 7" fill="none"><path d="M-5.75784e-08 2.82843L-1.07324e-07 7L9 7L3.41421 1.41421C2.15428 0.154284 -3.63306e-08 1.04662 -5.75784e-08 2.82843Z" fill="#19222B"/></svg>'
                                        }
                                   >
                                        <span className="ml-2" style={{ paddingBottom: '6px' }}>
                                             <CIcon name="iconAlertCircle" style={{ cursor: 'pointer', width: '16px', height: '20px' }} />
                                        </span>
                                   </Tippy>
                              </div>
                         </div>

                         <div className="d-flex justify-content-end align-items-center">
                              <CButton
                                   className="btn mr-3 text-transform-none btn-create"
                                   onClick={onClickCreate}
                                   disabled={(!showData) || isLoading}
                              >
                                   <i className="fal fa-plus mr-1"></i>
                                   Create new segment
                              </CButton>
                              <CButton className="btn-apply" color="primary" onClick={() => handleApply()} disabled={saveLoading || (maximumSegmentSelect.length === 0 && listSegmentReportApply.length === 0)}>
                                   {isLoading ? <span className="dots-waiting">Waiting</span> : 'Apply'}
                              </CButton>
                         </div>
                    </div>
               </div>
               {showDeleteModal && (
                    <ConfirmRemovePopup
                         show={showDeleteModal}
                         onAccept={handleConfirmClearAllClick}
                         acceptText={'PROCEED TO REMOVE'}
                         onClose={() => {
                              setShowDeleteModal(false);
                         }}
                         isLoading={saveLoading}
                    >
                         <p>You are about to clear all segments.</p>
                    </ConfirmRemovePopup>
               )}
          </>
     );
};

export default SegmentDropdown;
