import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import findIndex from 'lodash/findIndex';

import { getURLParams, toastError, tryParseJSON, cleanURL } from '../../utils';
import { callTokenApi } from '../../apiCaller';
import {
     API_CLIENT_ACCOUNTS,
     API_CLIENT_ACCOUNT,
     API_CLIENT_RULE,
     SUBSCRIBER_PATH,
     API_CLIENT_USER_TERMS,
     API_CLIENT_TERM_AND_CONDITION_PUBLISH,
     LOCAL_SETUP_INVITED_ACCOUNT,
     API_CLIENT_FIND_USER_ACCOUNT,
     SUBSCRIBER_MY_ACCOUNT_PATH,
     LOCAL_GTM_ACCESS_TOKEN,
     LOCAL_PREVIEW,
     API_CLIENT_DESTINATIONS_GTM_TOKEN,
     AUTH_PATH,
     LOCAL_ACCORDION_SHOW,
     PLATFORM_ACCOUNT_STATUSES,
     DORMANT_STATUSES,
     COMPONENT_NAME,
     IS_LAYOUT_FORM_CATEGORIES,
} from '../../constants';

import {
     setAccounts,
     setActiveAccount,
     setRoutes,
     fetchListeners,
     setLoadings,
     fetchVersion,
     setShowCreateAccountPopup,
     fetchAnAccount,
     setUserTerms,
     setTermPublish,
     fetchDataTickets,
     fetchMonitoringNotifications,
     setIsFromGetStarted,
     setTurnOffPreview,
     setShowPopupDomain,
     resetAllPreview,
     setAccountPreview,
     setGTMWizard,
     handleGetAccordion,
     setSwitchTypeWizard,
     setSetupGuideRoadmap,
     setShowSetupGuidePopup,
     setShowRoadmapMinimize,
     setDataSetupGuideDataCompleted,
     fetchCustomReportsRequest,
     setScheduleUpgrade,
     fetchDimensionsRequest,
     fetchEventNativeListener,
} from '../../actions/subscriber';
import { CSpinner } from '@coreui/react';

import { findAccount, urlParams } from '../../helpers/cms/subscriber';
import { subscriberNoAccountRoutes, defaultSubscriberRoutes } from '../../routes';

import SubscriberASide from './aside/TheAside';
import SubscriberContent from './TheContent';
import SubscriberFooter from './TheFooter';
import SubscriberHeader from './header/TheHeader';
import SubscriberSidebar from './TheSidebar';
import SubscriberSidebarV2 from './TheSidebarV2';
import SubscriberPusher from './ThePusher';
import SubscriberPreview from './ThePreview';
import TheMasterclassAside from './masterclass-aside/TheMasterclassAside';
import { useActiveDestination, useSubscriberGTMTracking } from '../../helpers/customHooks';
import { signout } from '../../helpers/auth';
import GTMWizard from '../../components/cms/subscriber/account/gtm-wizard';
import SubscriberTour from './TheTour';
import { toast } from 'react-toastify';
import CustomDestinationModal from '../../components/cms/subscriber/destinations/custom-destination-modal';
import FlexibleModal from './flexible-modal';
import { setFlexibleModal } from '../../actions/common';
import UnsavedChanges from './unsaved-changes';
import FlexibleMultiModal from './flexible-multi-modal';
import SwitchTypeWizard from '../../components/cms/subscriber/destination-settings/switch-type-wizard/SwitchTypeWizard';
import SetupGuidePopup from '../../components/general/popup/setup-guide/setupGuide';

const FullPageOverlay = () => (
     <div className="full-page-overlay">
          <CSpinner color="primary" />
     </div>
);

const SubscriberLayout = () => {
     const history = useHistory();
     const location = useLocation();
     const dispatch = useDispatch();
     const [isNoAccount, setIsNoAccount] = useState(false);
     const { showTour } = useSelector((state) => state.subscriber.tour);
     const { secondId } = useParams();
     const accounts = useSelector((state) => state.subscriber.accounts);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const activeAccountId = useSelector((state) => state.subscriber.activeAccount.id);
     const lastLoginAccount = useSelector((state) => state.theme.user.lastLoginAccount);
     const { showNewLayout } = useSelector((state) => state.subscriber.newLayout);
     const params = getURLParams(location.search.substring(1));
     const user = useSelector((state) => state.theme.user);
     const isCustomerSupportLoginAs = useSelector((state) => state.subscriber.isCustomerSupportLoginAs);
     const wholePageOverlay = useSelector((state) => state.subscriber.loadings.wholePageOverlay);
     const gtmWizardKey = useSelector((state) => state.subscriber.gtmWizard.key);
     const switchTypeWizardKey = useSelector((state) => state.subscriber.switchTypeWizard.key);
     const customDestinationModalKey = useSelector((state) => state.subscriber.customDestinationModal.key);
     const userId = user.id;
     const showMyAccount = Object.entries(SUBSCRIBER_MY_ACCOUNT_PATH).some(([_, value]) => location.pathname.includes(value));
     const localAccordionShow = tryParseJSON(localStorage.getItem(LOCAL_ACCORDION_SHOW));
     const pathName = history.location.pathname;
     const queryParams = new URLSearchParams(location.search);
     const showUnsavedChange = useSelector((state) => state.theme.showUnsavedChange);
     const activeDestination = useActiveDestination();

     const showBlockSetupGuidePopup = useSelector((state) => state.subscriber.showBlockSetupGuidePopup);
     const setShowRoadmapMininize = useSelector((state) => state.subscriber.setShowRoadmapMininize);

     useSubscriberGTMTracking(isNoAccount);

     if (params.userID && params.welcomeScreen === 'true' && params.userID !== userId) {
          signout();
     }

     // let isShowSavedView = queryParams.get(SHOW_SAVED_VIEW);

     // if (isShowSavedView === '1') {
     //      localStorage.setItem(SHOW_SAVED_VIEW, "enable");
     // }
     useEffect(() => {
          const isLayoutFormCategories = localStorage.getItem(IS_LAYOUT_FORM_CATEGORIES);
          if (localAccordionShow && pathName && !isLayoutFormCategories) {
               dispatch(handleGetAccordion({ pathName }));
          }
          if (isLayoutFormCategories) {
               localStorage.removeItem(IS_LAYOUT_FORM_CATEGORIES);
          }

          // Close guide popup when in my accounts
          if (pathName.includes(SUBSCRIBER_MY_ACCOUNT_PATH.MY_ACCOUNTS)) {
               dispatch(setShowSetupGuidePopup(false));
          }
     }, [pathName, localAccordionShow, activeAccountId, queryParams]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleOpenMonitoringRule = (ruleId, secondIdCustom) => {
          history.push(SUBSCRIBER_PATH.CUSTOM_MONITORING_RULES.replace(':secondId', secondIdCustom));
          dispatch(
               setFlexibleModal({
                    show: true,
                    ruleId,
                    component: COMPONENT_NAME.MONITORING_RULE,
               })
          );
     };

     const fetchMonitoringRule = (next = (f) => f, accounts) => {
          callTokenApi(`${API_CLIENT_RULE}${params.monitoringruleID}?getListener=true`, 'GET', null) // getListener=true: get listener alias
               .then((response) => {
                    if (response.status === 200) {
                         const { rule } = response.data;
                         const ruleAccountIndex = findIndex(accounts, { id: rule.accountId }); // find account of rule in all accounts of user

                         if (ruleAccountIndex === -1 || rule.group !== 'monitoring') {
                              // Could not find or this rule is not monitoring rule
                              next();
                         } else {
                              // Found
                              if (rule.accountId === lastLoginAccount) {
                                   // Don't switch account
                                   next();
                                   handleOpenMonitoringRule(params.monitoringruleID, accounts[ruleAccountIndex].secondId);     
                              } else {
                                   // Switch to account of rule & go to this monitoring rule URL
                                   const doNext = () => handleOpenMonitoringRule(params.monitoringruleID, accounts[ruleAccountIndex].secondId);
                                   dispatch(fetchAnAccount(accounts[ruleAccountIndex], accounts, doNext));
                              }
                         }
                    } else {
                         toastError(response);
                         next();
                    }
               });
     };

     // Fetch accounts of user and save to reducer
     const fetchAccounts = () => {
          // let userLocal =
          if (!localStorage['user']) {
               localStorage.setItem('userIdScreen', userId);
          }
          callTokenApi(`${API_CLIENT_ACCOUNTS}`, 'GET', null)
               .then((response) => {
                    if (response.status === 200) {
                         const { accounts: resAccounts } = response.data;
                         // let areAllAccountsInactive = isCustomerSupportLoginAs ? false : !!resAccounts.find(acc => acc.inactivePermanent || acc.inactiveTemporary);
                         // const accounts = isCustomerSupportLoginAs ? resAccounts : resAccounts.filter(acc => !acc.inactivePermanent && !acc.inactiveTemporary);
                         let areAllAccountsInactive = false;
                         const accounts = resAccounts;
                         const accountNotPermanentFilters = accounts
                              ? accounts.filter((account) => {
                                     return account.platformAccountStatus !== PLATFORM_ACCOUNT_STATUSES.DORMANT_PERMANENT;
                                })
                              : [];

                         dispatch(setAccounts(accounts));

                         if (
                              (isCustomerSupportLoginAs && accounts.length > 0) ||
                              (!isCustomerSupportLoginAs && accountNotPermanentFilters.length > 0)
                         ) {
                              // Handle setActiveAccount, setRoutes
                              const next = () => {
                                   // if lastLoginAccount doesn't have value, we automatically select the first account
                                   let activeAccount = accounts[0];

                                   if (secondId && secondId.startsWith('p') && secondId.length === 13) {
                                        activeAccount = findAccount(secondId, accounts);

                                        if (!activeAccount) {
                                             activeAccount = findAccount(window.localStorage.getItem('activeAccountId'), accounts);
                                        }
                                   } else {
                                        if (typeof params.accountID !== 'undefined') {
                                             activeAccount = findAccount(params.accountID, accounts) || accounts[0];
                                        } else if (typeof params.accountId !== 'undefined') {
                                             activeAccount = findAccount(params.accountId, accounts) || accounts[0];
                                        } else if (lastLoginAccount) {
                                             activeAccount = findAccount(lastLoginAccount, accounts) || accounts[0];
                                        }
                                   }
                                   
                                   if (!activeAccount) {
                                        activeAccount = accounts[0];
                                   }

                                   if (!isCustomerSupportLoginAs && DORMANT_STATUSES.includes(activeAccount.platformAccountStatus)) {
                                        const activeAccountFilters = accounts
                                             ? accounts.filter((account) => !DORMANT_STATUSES.includes(account.platformAccountStatus))
                                             : [];
                                        if (activeAccountFilters.length > 0) {
                                             activeAccount = activeAccountFilters[0];
                                        } else {
                                             const dormantTempAccountFilters = accounts
                                                  ? accounts.filter((account) => {
                                                         return account.platformAccountStatus === PLATFORM_ACCOUNT_STATUSES.DORMANT_TEMPORARY;
                                                    })
                                                  : [];
                                             if (dormantTempAccountFilters.length > 0) {
                                                  activeAccount = dormantTempAccountFilters[0];
                                             }
                                        }
                                   }

                                   dispatch(setActiveAccount(activeAccount));
                                   dispatch(setScheduleUpgrade(activeAccount.isScheduleUpgrade));
                                   dispatch(fetchMonitoringNotifications(activeAccount.accountId));
                                   localStorage.setItem('activeAccountId', activeAccount.accountId);
                                   localStorage.setItem(
                                        'activeAccount',
                                        JSON.stringify({
                                             id: activeAccount.accountId,
                                             name: activeAccount.name,
                                        })
                                   );
                                   dispatch(setRoutes(defaultSubscriberRoutes));

                                   if (
                                        (typeof params.accountID !== 'undefined' && !findAccount(params.accountID, accounts)) ||
                                        (typeof params.accountId !== 'undefined' && !findAccount(params.accountId, accounts))
                                   ) {
                                        history.replace({ pathname: window.location.pathname, search: '' });
                                   }

                                   let localSetupInvitedAccount = localStorage.getItem(LOCAL_SETUP_INVITED_ACCOUNT);

                                   let closeWelcome = localStorage.getItem('closeWelcome');

                                   if (closeWelcome === null) {
                                        callTokenApi(`${API_CLIENT_FIND_USER_ACCOUNT}${userId}/${activeAccount.accountId}`, 'GET', null).then(
                                             (response) => {
                                                  if (response.status === 200) {
                                                       const { userAccount } = response.data;
                                                       if (
                                                            localSetupInvitedAccount === 'yes' ||
                                                            (userAccount &&
                                                                 !userAccount.firstLoginAccount &&
                                                                 typeof userAccount.firstLoginAccount !== undefined &&
                                                                 userAccount.firstLoginAccount !== 'undefined')
                                                       ) {
                                                            localStorage.removeItem(LOCAL_SETUP_INVITED_ACCOUNT);
                                                            callTokenApi(`${API_CLIENT_ACCOUNT}${activeAccount.accountId}`, 'GET', null);
                                                            const hash = history.location.hash;

                                                            if (!hash || hash !== '#payment-method') {
                                                                 history.push(AUTH_PATH.WELCOME_SCREEN);
                                                            }
                                                       }
                                                  }
                                             }
                                        );
                                   } else {
                                        localStorage.removeItem('closeWelcome');
                                   }
                              };

                              if (params.monitoringruleID) {
                                   // The current URL has monitoringruleID as a param
                                   fetchMonitoringRule(next, accounts);
                              } else {
                                   next();
                              }
                         } else {
                              window._areAllAccountsInactive = areAllAccountsInactive;
                              setIsNoAccount(true);

                              const _registerMasterClass = urlParams('registerMasterClass');
                              const _pathName = window.location.pathname.replace('/', '');

                              const _arrSlug = [
                                   'masterclass',
                                   'certification',
                                   'office-hours',
                                   'become-a-partner',
                                   'support-tickets',
                                   'profile',
                                   'email-notifications',
                                   'payment-methods',
                              ];

                              if (user.userCourse.hasCourse) {
                                   if (_pathName !== '') {
                                        history.push(`/${_pathName}`);
                                   } else {
                                        history.push(SUBSCRIBER_MY_ACCOUNT_PATH.MASTERCLASS);
                                   }
                              } else {
                                   if (!!_arrSlug.find((slug) => _pathName.includes(slug))) {
                                        if (user.registerMasterClass || _registerMasterClass) {
                                             history.push(`${SUBSCRIBER_MY_ACCOUNT_PATH.MASTERCLASS}?registerMasterClass=true`);
                                        } else {
                                             history.push(`/${_pathName}`);
                                        }
                                   } else {
                                        dispatch(setShowCreateAccountPopup(true));
                                   }
                              }

                              dispatch(setRoutes(subscriberNoAccountRoutes));
                              dispatch(setLoadings({ listeners: false }));
                         }
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => dispatch(setLoadings({ accounts: false })));
     };

     const fetchUserTerms = () => {
          callTokenApi(`${API_CLIENT_USER_TERMS}`, 'GET', null).then((response) => {
               if (response.status === 200) {
                    const { terms } = response.data;
                    dispatch(setUserTerms(terms));
               }
          });
     };

     const fetchTermPublish = () => {
          callTokenApi(`${API_CLIENT_TERM_AND_CONDITION_PUBLISH}`, 'GET', null).then((response) => {
               if (response.status === 200) {
                    const { termAndCondition } = response.data;
                    dispatch(setTermPublish(termAndCondition));
               }
          });
     };

     const handleOAuthGTMResponse = () => {
          const params = getURLParams();

          const generalProcess = (state, isBackFromGTM) => {
               let gtmState = tryParseJSON(decodeURIComponent(atob(state)));

               if (gtmState && typeof gtmState === 'object') {
                    const { accountId, isFromGTMWizard, isFromSwitchTypeWizard, gtmWizard, switchTypeWizard, pathname, isFromGetStarted } = gtmState;
                    gtmState.isBackFromGTM = isBackFromGTM;

                    const handleNext = () => {
                         if (isFromGTMWizard) {
                              window._tempGTMWizardParams = { ...gtmState };
                              window._tempGTMWizardParams.code = params.code;
                              dispatch(setGTMWizard(gtmWizard));
                              history.push(pathname);
                              return;
                         }

                         if (isFromSwitchTypeWizard) {
                              dispatch(setSwitchTypeWizard(switchTypeWizard));
                              history.push(pathname);
                              return;
                         }

                         window._tempGTMParams = { ...gtmState };

                         if (window._tempGTMParams && typeof window._tempGTMParams === 'object' && isFromGetStarted) {
                              dispatch(setIsFromGetStarted(true));
                         }

                         dispatch(setShowCreateAccountPopup(true));
                    };

                    if (isBackFromGTM) {
                         handleNext();
                    } else {
                         dispatch(setLoadings({ gtmToken: true }));
                         callTokenApi(API_CLIENT_DESTINATIONS_GTM_TOKEN, 'POST', { accountId, code: params.code })
                              .then((response) => {
                                   if (response.status === 200) {
                                        localStorage.setItem(LOCAL_GTM_ACCESS_TOKEN, response.data.accessToken);

                                        handleNext();
                                   } else {
                                        toastError(response);
                                   }
                              })
                              .finally(() => dispatch(setLoadings({ gtmToken: false })));
                    }
               }
          };

          // Make sure it's from google tag manager
          if (params.code) {
               cleanURL();
               const gtmPermissions = [
                    'tagmanager.edit.containers',
                    'tagmanager.publish',
                    'tagmanager.manage.users',
                    'tagmanager.edit.containerversions',
               ];

               if (gtmPermissions.every((gtmPermission) => params.scope.includes(gtmPermission))) {
                    generalProcess(params.state);
               } else {
                    toast.error('Insufficient permissions, please allow all permissions.', { autoClose: 5000 });
               }

               return;
          }

          // Click back button on browser when selecting account to connect to GTM
          if (params.isBackFromGTM === 'true') {
               cleanURL();
               generalProcess(params.state, true);
               return;
          }
     };

     const fetchInit = () => {
          fetchAccounts();
          fetchUserTerms();
          fetchTermPublish();
          handleOAuthGTMResponse();
     };

     useEffect(fetchInit, []);
     const fetchOptions = () => {
          if (activeAccountId) {
               // dispatch(fetchSavedReportsRequest(activeAccountId, userId));
               dispatch(fetchListeners(activeAccountId, activeAccount.secondId));
               dispatch(fetchCustomReportsRequest(activeAccountId));
               dispatch(fetchDimensionsRequest(activeAccountId));
               dispatch(fetchVersion(activeAccountId, true));
               dispatch(fetchDataTickets(activeAccountId, isCustomerSupportLoginAs));
               dispatch(fetchEventNativeListener(activeAccountId));
          }
     };
     useEffect(fetchOptions, [activeAccountId]);

     useEffect(() => {
          const isDev = localStorage.getItem('Sc1DevTest');

          if (isCustomerSupportLoginAs && !isDev) {
               const interval = setInterval(() => {
                    signout(true);
               }, 600000); // logout after 10m
               return () => clearInterval(interval);
          }
     }, [isCustomerSupportLoginAs]);

     const darkMode = useSelector((state) => state.theme.darkMode);
     const preview = useSelector((state) => state.subscriber.preview);

     useEffect(() => {
          if (activeAccountId) {
               dispatch(setSetupGuideRoadmap(-1));
               dispatch(setShowSetupGuidePopup(false));
               dispatch(setShowRoadmapMinimize(false));
               dispatch(setDataSetupGuideDataCompleted(null));
               const previewLocal = tryParseJSON(localStorage.getItem(LOCAL_PREVIEW));

               if (previewLocal && previewLocal.accountId) {
                    dispatch(setAccountPreview(previewLocal));
                    if (!previewLocal.accountId && previewLocal.accountId !== activeAccountId) {
                         dispatch(resetAllPreview(previewLocal.accountId));
                    } else if (previewLocal.show) {
                         setTimeout(() => {
                              document.body.classList.add('no-scroll');
                         }, 2000);
                         dispatch(setShowPopupDomain(false));
                    }
               }
          }
     }, [activeAccountId]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (preview && preview.accountId && activeAccountId && preview.accountId === activeAccountId) {
               localStorage.setItem(LOCAL_PREVIEW, JSON.stringify(preview));
          }
     }, [preview, activeAccountId]);

     useEffect(() => {
          const accountDetail = accounts.find(_ac => _ac.secondId === secondId);

          if (!accountDetail) {
               const userDetail = accounts.find(_ac => _ac.id === lastLoginAccount);

               if (userDetail && secondId !== 'my-account') {
                    history.push(`/${userDetail.secondId}${pathName}`);
               }
          }
     }, [accounts]) // eslint-disable-line react-hooks/exhaustive-deps

     const classes = classNames('subscriber c-app c-default-layout', { 'c-dark-theme': darkMode });

     const previewClose = () => {
          dispatch(setTurnOffPreview(false));
     };

     return (
          <div className={classes}>
               {wholePageOverlay && <FullPageOverlay />}
               {!showMyAccount ? <SubscriberSidebarV2 /> : <SubscriberSidebar />}
               <SubscriberASide />
               <TheMasterclassAside />
               <div className={`c-wrapper ${showNewLayout && !showMyAccount ? 'v2' : ''}${showTour ? ' show-tour' : ''}`}>
                    <SubscriberHeader />
                    <div className="c-body">
                         <SubscriberContent />
                    </div>
                    <SubscriberFooter />
               </div>
               {/* Add key to GTMWizard so whenever the key is changed, GTMWizard will render like the first time rendering */}
               {/* With this method I don't need to remove and add component again to reload the component */}
               {activeAccountId && <GTMWizard key={gtmWizardKey} />}
               {activeAccountId && activeDestination && <SwitchTypeWizard key={switchTypeWizardKey} />}
               {activeAccountId && <CustomDestinationModal key={customDestinationModalKey} />}
               {(preview.show || preview.running) && activeAccountId && (
                    <SubscriberPreview show={preview.show} onClose={previewClose} previewRunning={preview.running} />
               )}
               <FlexibleModal />
               <FlexibleMultiModal />
               {showUnsavedChange && <UnsavedChanges />}
               <SubscriberPusher />
               <SubscriberTour />
               {(showBlockSetupGuidePopup || setShowRoadmapMininize) && <SetupGuidePopup show={showBlockSetupGuidePopup} />}
          </div>
     );
};

export default SubscriberLayout;
