import CIcon from '@coreui/icons-react';
import { CButton, CForm, CFormGroup, CInput, CInvalidFeedback, CLabel } from '@coreui/react';
import { Formik } from 'formik';
import get from 'lodash/get';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Select from 'react-select';
import * as Yup from 'yup';
import { callTokenApi } from '../../../../apiCaller';
import { API_CLIENT_REPORT_FILTER_CHECKLIST, DEFAULT_DATE_FORMAT } from '../../../../constants';
import {
     CHECK_LIST_OPERATOR_OPTION,
     // FILTER_DATA_TYPES,
     IGNORE_FILTER_OPTIONS,
     REPORT_DATA_TYPES,
     REPORT_NAMES,
     REPORT_TAB_TYPES,
     VARIABLE_OPERATORS_DIMENSION_REPORT,
     VARIABLE_OPERATORS_DIMENSION_REPORT_DATE,
     VARIABLE_OPERATORS_METRICS_REPORT,
     VARIABLE_OPERATORS_REPORT,
     listOptionsAddmore,
} from '../../../../constants/analytics';
import SelectSearchField from '../../../general/custom-search-select/SelectSearchField';
import SelectField from '../../../general/multiselect/SelectField';
import CustomFilterDatePicker from './CustomFilterDatePicker';
import { EventExplorerContext } from './EventExplorer';
import { isEqual } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

const CHECKLIST_TYPES = ['industries', 'revenue', 'employees', 'primaryIndustry', 'companyState', 'companyCountry'];

const validationSchema = () => {
     return Yup.array(
          Yup.object().shape({
               type: Yup.string().trim().required('field required'),
               value: Yup.string().trim().required('field required'),
          })
     );
};

const getFinalFilterOptions = (data) => {
     const { dataParamValue, reportName, options, filters, dimensions, filterProperties, filterMetric } = data;
     const rejectOptions = ['personConvRate', 'userConvRate', 'sessionConvRate', 'companyConvRate', 'viewDetail', 'purchaseConversionRate'];
     let newOptions = options.filter((o) => !rejectOptions.includes(o.value) && !o.value.includes('conversionRate') && !o.value.includes('convRate'));

     const isFilterFromLink = dataParamValue && dataParamValue.filters && isEqual(dataParamValue.filters, filters);

     // If filter is not from link
     if (!isFilterFromLink && IGNORE_FILTER_OPTIONS[reportName]) {
          newOptions = newOptions.filter((o) => !IGNORE_FILTER_OPTIONS[reportName].includes(o.value));
     }

     newOptions = newOptions.filter((item) => {
          if (
               dimensions.some((e) => e.key === item.value) ||
               [...filterProperties, ...filterMetric].some((e) => e.value === item.value) 
          ) {
               return true;
          }
          return false;
     });

     return newOptions;
};

const ButtonClearAll = ({ handleClearAll, triggerClear, setTriggerClear }) => {
     useEffect(() => {
          if (triggerClear) {
               handleClearAll();
               setTriggerClear(false);
          }
     }, [triggerClear]); // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <CButton onClick={handleClearAll}>
               <CLabel>clear all</CLabel>
               <CIcon name="iconDeleteCircle" />
          </CButton>
     );
};

const FilterReport = () => {
     const history = useHistory();
     const {
          reportType,
          filterEvent,
          setFilterEvent,
          setFilterEventReport,
          filterEventReport,
          reportName,
          filterMetric,
          filterDimensions,
          filterProperties,
          filterOptions,
          showData,
          setChangeData,
          recordProfile,
          newDateRange,
          customProperties,
          // handleGetOption,
          // metricsStepForm,
          // isRemoveCustomPropertyHaveFilter,
          // setIsRemoveCustomPropertyHaveFilter,
          scoped,
          dataParamValue,
          isChangeScope,
          triggerClear,
          setTriggerClear,
          handleFindView,
     } = useContext(EventExplorerContext);
     const isFilter = useRef(false);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     const [checklistData, setChecklistData] = useState(null);
     const [formKey, setFormKey] = useState(0);
     const defaultRow = [
          {
               type: '',
               value: '',
               id: '',
               operator: 'ct',
               optionsOperator: VARIABLE_OPERATORS_REPORT,
          },
     ];
     const { dimensions } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];
     const [localFilterEvent, setLocalFilterEvent] = useState(() => (dataParamValue || filterEvent) && cloneDeep(filterEvent));

     const reloadForm = () => {
          // Tăng giá trị của key để khiến React render lại component Formik
          setFormKey((prevKey) => prevKey + 1);
     };
     useEffect(() => {
          reloadForm();
     }, [filterMetric, filterProperties]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (isChangeScope.current) {
               setLocalFilterEvent(defaultRow);
          }
     }, [scoped]); // eslint-disable-line react-hooks/exhaustive-deps
     useEffect(() => {
               setLocalFilterEvent(() => (dataParamValue || filterEvent) && cloneDeep(filterEvent));
     }, [filterEvent]); // eslint-disable-line react-hooks/exhaustive-deps

     // const initialValue = localFilterEvent;
     const dateRanges = {
          selection: {
               startDate: dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
               endDate: dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
          },
     };
     const customPropertiesNotSelect = [];
     // const defaultFilter = FILTER_DATA_TYPES[reportName];

     customProperties.forEach((element) => {
          if (
               !filterProperties.find((e) => e.value === element.properties)
          ) {
               customPropertiesNotSelect.push(element.properties);
          }
     });

     const initFilterOptions = filterOptions.filter((item) => {
          const listOption = item.value !== customPropertiesNotSelect.find((el) => el === item.value);
          if (!recordProfile) return listOption;
          return listOption && item.value !== localFilterEvent[0].type;
     });

     const typeOptions = getFinalFilterOptions({
          dataParamValue,
          reportName,
          options: initFilterOptions || filterOptions,
          filters: localFilterEvent,
          scoped,
          dimensions,
          filterProperties,
          filterMetric,
     });

     const checklistOptions = {};

     if (checklistData) {
          Object.entries(checklistData).forEach(([key, value]) => {
               checklistOptions[key] = value.map((item) => ({
                    label: item,
                    value: item,
               }));
          });
     }

     const fetchCheckListData = () => {
          const reqData = {
               reportName,
               accountId: activeAccount.id,
               dateRanges,
          };
          callTokenApi(API_CLIENT_REPORT_FILTER_CHECKLIST, 'POST', reqData).then((response) => {
               if (response && response.status === 200) {
                    setChecklistData(response.data.checklistData);
               }
          });
     };

     useEffect(() => {
          if (reportName === REPORT_NAMES.COMPANY_DETAILS) {
               fetchCheckListData();
          }
     }, [reportName, dateRangeReports]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (filterEvent[0].type !== defaultRow[0].type) {
               isFilter.current = true;
          }
          if (!recordProfile) {
               setLocalFilterEvent(cloneDeep(filterEvent));
          }
     }, [reportName, dateRangeReports, filterProperties]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleChangeOperatorFilter = ({ index, value, newFilterEvent = [] }) => {
          let operators = [];
          const findValueOfDimensions =
               filterDimensions.find((item) => item.value === value) || filterProperties.find((item) => item.value === value);
          const findValueOfMetric = filterMetric.find((item) => item.value === value);

          if (findValueOfDimensions) {
               if (['sessionNumber', 'conversionValue', 'sessionCountRow', 'totalSessionDurationType', 'conversionCountRow'].includes(value)) {
                    operators = VARIABLE_OPERATORS_METRICS_REPORT;

                    if (!VARIABLE_OPERATORS_METRICS_REPORT.find((item) => item.value === newFilterEvent[index].operator)) {
                         newFilterEvent[index] = { ...newFilterEvent[index], [`operator`]: 'eq' };
                    }
               } else if (value.toLowerCase().includes('date')) {
                    operators = VARIABLE_OPERATORS_DIMENSION_REPORT_DATE;
                    if (['latestSubmissionDate', 'lastConversionDate'].includes(value)) {
                         operators = [
                              { value: 'ct', label: 'contains' },
                              { value: 'nct', label: 'not contain' },
                              ...VARIABLE_OPERATORS_DIMENSION_REPORT_DATE,
                         ];
                    }
                    if (!VARIABLE_OPERATORS_DIMENSION_REPORT_DATE.find((item) => item.value === newFilterEvent[index].operator)) {
                         newFilterEvent[index] = { ...newFilterEvent[index], [`operator`]: 'on' };
                    }
               } else {
                    operators = VARIABLE_OPERATORS_DIMENSION_REPORT;

                    if (!VARIABLE_OPERATORS_DIMENSION_REPORT.find((item) => item.value === newFilterEvent[index].operator)) {
                         newFilterEvent[index] = { ...newFilterEvent[index], [`operator`]: 'ct' };
                    }
               }
          } else if (findValueOfMetric) {
               operators = VARIABLE_OPERATORS_METRICS_REPORT;

               if (!VARIABLE_OPERATORS_METRICS_REPORT.find((item) => item.value === newFilterEvent[index].operator)) {
                    newFilterEvent[index] = { ...newFilterEvent[index], [`operator`]: 'eq' };
               }
          } else if (value.toLowerCase().includes('date')) {
               operators = VARIABLE_OPERATORS_DIMENSION_REPORT_DATE;
               if (['latestSubmissionDate', 'lastConversionDate'].includes(value)) {
                    operators = [
                         { value: 'ct', label: 'contains' },
                         { value: 'nct', label: 'not contain' },
                         ...VARIABLE_OPERATORS_DIMENSION_REPORT_DATE,
                    ];
               }
               newFilterEvent[index] = { ...newFilterEvent[index], [`operator`]: 'on' };
          } else {
               if (listOptionsAddmore.includes(value) || value.includes('step')) {
                    operators = VARIABLE_OPERATORS_METRICS_REPORT;
                    newFilterEvent[index] = { ...newFilterEvent[index], [`operator`]: VARIABLE_OPERATORS_METRICS_REPORT[0].value };
               } else {
                    operators = VARIABLE_OPERATORS_DIMENSION_REPORT;
               }
          }

          // Add checklist operator option
          if (findValueOfDimensions && CHECKLIST_TYPES.includes(findValueOfDimensions.value)) {
               if (!operators.some((item) => item.value === CHECK_LIST_OPERATOR_OPTION.value)) {
                    operators.push(CHECK_LIST_OPERATOR_OPTION);
               }
          } else {
               operators = [...operators].filter((item) => item.value !== CHECK_LIST_OPERATOR_OPTION.value);
          }

          if (!recordProfile && value.toLowerCase().includes('date')) {
               operators = [...operators, { value: 'dr', label: 'in the date range' }];
          }

          return operators;
     };
     const handleSubmit = (values) => {
          
          if (!showData) return;

          setChangeData(true);
          isFilter.current = true;
          setFilterEvent(values);
          setFilterEventReport(!filterEventReport);
          setLocalFilterEvent(values);
          if (reportName === 'eventDetails') {
               history.push(window.location.pathname);
          }

          handleFindView(REPORT_TAB_TYPES.FILTER, values);
     };

     return (
          <div className="filter-report">
               <Formik
                    key={formKey}
                    initialValues={localFilterEvent}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize
               >
                    {({
                         values,
                         errors,
                         handleChange,
                         handleSubmit,
                         setErrors,
                         isSubmitting,
                         setFieldValue,
                         setValues,
                         touched,
                         setFieldTouched,
                    }) => {
                         const handleChangeFilter = ({ valueFilter, position, type }) => {
                              const { value } = valueFilter;
                              const newFilterEvent = [...values];

                              if (type === 'type') {
                                   const optionsOperator = handleChangeOperatorFilter({
                                        index: position,
                                        value: value,
                                        newFilterEvent: newFilterEvent,
                                   });
                                   newFilterEvent[position] = { ...newFilterEvent[position], [`optionsOperator`]: optionsOperator };
                              }
                              if (value === 'dr') {
                                   newFilterEvent[position] = { ...newFilterEvent[position], [`value`]: newDateRange };
                              } else if (valueFilter.value.includes('Date') && newFilterEvent[position].operator === 'dr') {
                                   newFilterEvent[position] = { ...newFilterEvent[position], [`value`]: newDateRange };
                              } else {
                                   newFilterEvent[position] = { ...newFilterEvent[position], [`value`]: '' };
                              }

                              newFilterEvent[position] = { ...newFilterEvent[position], [`${type}`]: value };
                              setValues(newFilterEvent);
                              // setLocalFilterEvent(newFilterEvent);
                              setErrors({});
                         };

                         const handleDeleteFilter = (index) => {
                              const newFilterEvent = [...values];
                              newFilterEvent.splice(index, 1);
                              setValues(newFilterEvent);
                              setErrors({});
                         };

                         const handleChangeValueFilter = (e, index) => {
                              const { name, value } = e.target;
                              const newFilterEvent = [...values];
                              newFilterEvent[index] = { ...newFilterEvent[index], [name]: value };
                              setValues(newFilterEvent);
                              // setLocalFilterEvent(newFilterEvent);
                         };

                         const handleClearAll = () => {
                              if (!showData) return;

                              if (isFilter.current) {
                                   setChangeData(true);
                                   setFilterEventReport(!filterEventReport);
                              }

                              if (!recordProfile) {
                                   setValues(defaultRow);
                                   setFilterEvent(defaultRow);
                                   handleFindView(REPORT_TAB_TYPES.FILTER, defaultRow);
                              } else {
                                   setValues([filterEvent[0]]);
                                   setFilterEvent([filterEvent[0]]);
                                   handleFindView(REPORT_TAB_TYPES.FILTER, [filterEvent[0]]);
                              }
                              setFilterEventReport(!filterEventReport);
                              isFilter.current = false;
                              setErrors({});
                         };

                         const handleAddRow = () => {
                              if (showData) {
                                   setValues([...values, ...defaultRow]);
                              }
                         };

                         const handleChangeValueFilterDatePicker = (date, index) => {
                              const newFilterEvent = [...values];
                              newFilterEvent[index] = { ...newFilterEvent[index], [`value`]: date };
                              setValues(newFilterEvent);
                         };

                         const disableClearAll = values && values.length === 1 && !values[0].value;
                         return (
                              <CForm onSubmit={handleSubmit} noValidate>
                                   <div className="d-flex align-items-center justify-content-end">
                                        <ButtonClearAll
                                             handleClearAll={handleClearAll}
                                             triggerClear={triggerClear}
                                             setTriggerClear={setTriggerClear}
                                             disabled={disableClearAll}
                                        />
                                        <CButton onClick={handleSubmit} type="submit" className={`filter-button ${JSON.stringify(values) === JSON.stringify(localFilterEvent) ? 'disabled' : ''}`}>
                                             <CLabel>Apply</CLabel>
                                        </CButton>
                                   </div>
                                   {values.map((item, index) => {
                                        const typeValid = get(errors, `[${index}].type`);
                                        const valueValid = get(errors, `[${index}].value`);
                                        const operatorValue = item.optionsOperator.find((option) => option.value === item.operator) || {
                                             value: '',
                                             label: 'Select...',
                                        };
                                        const showDatePicker = item.type.includes('Date') && !['ct', 'nct'].includes(item.operator);

                                        return (
                                             <Fragment key={index}>
                                                  <div
                                                       className={`d-flex align-items-top justify-content-end mt-2 position-relative ${
                                                            recordProfile && index === 0 ? 'hide-delete-filter' : 0
                                                       }`}
                                                  >
                                                       <div className={`select-filter ${typeValid ? 'is-invalid' : ''} `}>
                                                            <SelectSearchField
                                                                 id="type"
                                                                 placeholder="Select..."
                                                                 options={typeOptions}
                                                                 value={
                                                                      filterOptions.find((option) => option.value === item.type) || {
                                                                           value: '',
                                                                           label: 'Select...',
                                                                      }
                                                                 }
                                                                 className={`select-react`}
                                                                 classNamePrefix="custom-select"
                                                                 isDisabled={recordProfile && index === 0}
                                                                 onChange={(e) =>
                                                                      handleChangeFilter({
                                                                           valueFilter: e,
                                                                           position: index,
                                                                           type: 'type',
                                                                      })
                                                                 }
                                                            />
                                                            {!!typeValid && <CInvalidFeedback className="d-block mb-0">{typeValid}</CInvalidFeedback>}
                                                       </div>
                                                       <div className="select-filter-operator">
                                                            <Select
                                                                 name="value"
                                                                 options={item.optionsOperator}
                                                                 value={operatorValue}
                                                                 placeholder="Select..."
                                                                 onChange={(e) =>
                                                                      handleChangeFilter({
                                                                           valueFilter: e,
                                                                           position: index,
                                                                           type: 'operator',
                                                                      })
                                                                 }
                                                                 isDisabled={recordProfile && index === 0}
                                                            />
                                                       </div>
                                                       <div className={`field-value-search `}>
                                                            {item.operator !== 'dr' && (
                                                                 <div className={`${valueValid ? 'is-invalid' : ''}`}>
                                                                      {showDatePicker ? (
                                                                           <CustomFilterDatePicker
                                                                                onChange={(e) => handleChangeValueFilterDatePicker(e, index)}
                                                                                value={item.value}
                                                                           />
                                                                      ) : operatorValue.value === CHECK_LIST_OPERATOR_OPTION.value ? (
                                                                           <SelectField
                                                                                id={`${index}.value`}
                                                                                name={`${index}.value`}
                                                                                options={checklistOptions[item.type] || []}
                                                                                value={item.value}
                                                                                isMulti={true}
                                                                                onChange={setFieldValue}
                                                                                onBlur={setFieldTouched}
                                                                                touched={touched.checklist}
                                                                                error={errors.checklist}
                                                                                isClearable={true}
                                                                                backspaceRemovesValue={true}
                                                                                setErrors={setErrors}
                                                                           />
                                                                      ) : (
                                                                           <CInput
                                                                                value={typeof item.value === 'string' ? item.value : ''}
                                                                                name="value"
                                                                                onChange={(e) => handleChangeValueFilter(e, index)}
                                                                                disabled={recordProfile && index === 0}
                                                                           />
                                                                      )}
                                                                 </div>
                                                            )}
                                                            {!!valueValid && (
                                                                 <CInvalidFeedback className="d-block mb-0">{valueValid}</CInvalidFeedback>
                                                            )}
                                                       </div>
                                                       {((!recordProfile && values.length > 1) ||
                                                            (recordProfile && index !== 0 && values.length > 1)) && (
                                                            <CButton
                                                                 className="button-group field-parameter-button"
                                                                 onClick={() => handleDeleteFilter(index)}
                                                            >
                                                                 <CIcon name="iconDeleteField" className="icon-delete" />
                                                            </CButton>
                                                       )}
                                                  </div>
                                             </Fragment>
                                        );
                                   })}
                                   <CFormGroup className=" d-flex align-items-center justify-content-end">
                                        <CButton className="btn-add-row" onClick={handleAddRow}>
                                             <CIcon name="iconAddField" className="icon-add" />
                                             <CLabel className="add-row">ADD ROW</CLabel>
                                        </CButton>
                                   </CFormGroup>
                              </CForm>
                         );
                    }}
               </Formik>
          </div>
     );
};

export default FilterReport;
